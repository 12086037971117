// Generated by Framer (3bc9980)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
const MaterialFonts = getFonts(Material);

const serializationHash = "framer-RqGIz"

const variantClassNames = {PR1iy_0qT: "framer-v-11bi6ar"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const transition2 = {bounce: 0, delay: 0, duration: 0.45, type: "spring"}

const animation = {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1.03, skewX: 0, skewY: 0, transition: transition2}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "PR1iy_0qT", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-11bi6ar", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"PR1iy_0qT"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-gp1esl-container"} layoutDependency={layoutDependency} layoutId={"Xcd63wO_a-container"} whileHover={animation}><Material color={"rgba(0, 0, 0, 0.4)"} height={"100%"} iconSearch={"Chevron Right"} iconSelection={"ChevronRight"} iconStyle15={"Rounded"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"Xcd63wO_a"} layoutId={"Xcd63wO_a"} mirrored selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-RqGIz.framer-4j0k7u, .framer-RqGIz .framer-4j0k7u { display: block; }", ".framer-RqGIz.framer-11bi6ar { height: 30px; overflow: visible; position: relative; width: 30px; }", ".framer-RqGIz .framer-gp1esl-container { bottom: 0px; cursor: pointer; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 30
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerINL8zu9z3: React.ComponentType<Props> = withCSS(Component, css, "framer-RqGIz") as typeof Component;
export default FramerINL8zu9z3;

FramerINL8zu9z3.displayName = "Left";

FramerINL8zu9z3.defaultProps = {height: 30, width: 30};

addFonts(FramerINL8zu9z3, [{explicitInter: true, fonts: []}, ...MaterialFonts], {supportsExplicitInterCodegen: true})